<template>
  <div>
  <div class="row d-flex align-items-center no-gutters pb-3 mb-4 border-bottom">
    <div class="col-9 d-flex align-items-center">
      <router-link to="/">
        <img src="../../../public/img/logo_2018.png" style="width: 221px; height: 55px" alt="E-Crane Equilibrium Logo">
      </router-link>
    </div>
    <div class="col-3 text-right">
      <busy-btn size="lg" variant="primary" @click="login" accesskey="l"><span class="accesskey">L</span>ogin</busy-btn>
    </div>
  </div>
<!--    <b-alert show variant="warning" class="mb-4">E-CARE will be down for maintenance on Thursday 3rd of April 2025 from 06:00 until 10:00 UTC</b-alert>-->
  </div>

</template>
<script>
import postLogin from '@/lib/postLogin';
import store2    from '@/lib/store2';

export default
{
    computed:
    {
        $state() { return store2(this.$pinia) }
    },
    methods:
    {
        async login()
        {
            try
            {
                const { data } = await this.$apollo.mutate({ mutation: require('../../gql/mutations/loginCheck.gql') });

                const boundary = Date.now() + 1209600000;

                if(data.loginCheck.valid && (data.loginCheck.expires > boundary))
                {
                    await this.$state.loginExisting(this);
                    await postLogin(this.$router, this.$route);
                }
                else
                {
                    await this.$router.push('/login');
                }
            }
            catch (e)
            {
                console.error(e);
                await this.$router.push('/login');
            }

        }
    }
}
</script>